import { Controller } from "@hotwired/stimulus";
import { refreshVMasker } from "../src/admin/initializers/vmasker";
import { createElement } from "dropzone";

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = [
    "gatewaySelected",
    "hasRateZoop",
    "hasRateAbmex",
    "hasRateAkta",
    "hasRateHorizon",
    "hasRateOrla",
    "hasRateUltragate",
    "hasRateZolluspay",
    "hasRateMorgan",
    "hasRateMorganpay",
    "hasRateNoxpay",
    "hasRateZouti",
    "hasRateBeehive",
    "hasRateCashtime",
    "hasRateDomPagamento",
    "hasRateNeverra",
    "hasRateMevpay",
    "hasRateHypepay",
    "hasRateBrazapay",
    "hasRateBlackBullPay",
    "hasRateSoutpay",
    "hasRateZyonPay",
    "hasRateBestfy",
    "hasRateSkalepay",
    "hasRateArkama",
    "hasRateDorapag",
    "hasRateAurapay",
    "hasRate",
    "hasRateInstallment",
    "hasRateInstallmentAkta",
    "hasRateInstallmentHorizon",
    "hasRateInstallmentOrla",
    "hasRateInstallmentUltragate",
    "hasRateInstallmentZolluspay",
    "hasRateInstallmentMorgan",
    "hasRateInstallmentMorganpay",
    "hasRateInstallmentNoxpay",
    "hasRateInstallmentZouti",
    "hasRateInstallmentBeehive",
    "hasRateInstallmentCashtime",
    "hasRateInstallmentDomPagamento",
    "hasRateInstallmentNeverra",
    "hasRateInstallmentMevpay",
    "hasRateInstallmentBrazapay",
    "hasRateInstallmentHypepay",
    "hasRateInstallmentBlackBullPay",
    "hasRateInstallmentSoutpay",
    "hasRateInstallmentZyonPay",
    "hasRateInstallmentBestfy",
    "hasRateInstallmentSkalepay",
    "hasRateInstallmentArkama",
    "hasRateInstallmentDorapag",
    "hasRateInstallmentAurapay",
    "additionalFields",
    "documentLabel",
    "documentField",
  ];

  initialize() {
    $(".gateway-form-list").addClass('d-none')
    let labels = document.querySelectorAll(".form-check-label");
    for (let label of labels) {
      label.classList.add("d-none");
    }
  }

  showRateField(e) {
    if (e.target.value == "true") {
      this.hasRateTarget.classList.remove("d-none");
      this.hasRateInstallmentTarget.classList.remove("d-none");
    } else {
      this.hasRateTarget.classList.add("d-none");
      this.hasRateInstallmentTarget.classList.add("d-none");
    }
  }

  showRateFieldAbmex(e) {
    if (e.target.value == "true") {
      this.hasRateAbmexTarget.classList.remove("d-none");
      this.hasRateInstallmentTarget.classList.remove("d-none");
    } else {
      this.hasRateAbmexTarget.classList.add("d-none");
      this.hasRateInstallmentTarget.classList.add("d-none");
    }
  }

  showRateFieldAkta(e) {
    if (e.target.value == "true") {
      this.hasRateAktaTarget.classList.remove("d-none");
      this.hasRateInstallmentAktaTarget.classList.remove("d-none");
    } else {
      this.hasRateAktaTarget.classList.add("d-none");
      this.hasRateInstallmentAktaTarget.classList.add("d-none");
    }
  }

  showRateFieldDomPagamento(e) {
    if (e.target.value == "true") {
      this.hasRateDomPagamentoTarget.classList.remove("d-none");
      this.hasRateInstallmentDomPagamentoTarget.classList.remove("d-none");
    } else {
      this.hasRateDomPagamentoTarget.classList.add("d-none");
      this.hasRateInstallmentDomPagamentoTarget.classList.add("d-none");
    }
  }

  showRateFieldCashtime(e) {
    if (e.target.value == "true") {
      this.hasRateCashtimeTarget.classList.remove("d-none");
      this.hasRateInstallmentCashtimeTarget.classList.remove("d-none");
    } else {
      this.hasRateCashtimeTarget.classList.add("d-none");
      this.hasRateInstallmentCashtimeTarget.classList.add("d-none");
    }
  }

  showRateFieldNoxpay(e) {
    if (e.target.value == "true") {
      this.hasRateNoxpayTarget.classList.remove("d-none");
      this.hasRateInstallmentNoxpayTarget.classList.remove("d-none");
    } else {
      this.hasRateNoxpayTarget.classList.add("d-none");
      this.hasRateInstallmentNoxpayTarget.classList.add("d-none");
    }
  }

  showRateFieldBeehive(e) {
    if (e.target.value == "true") {
      this.hasRateBeehiveTarget.classList.remove("d-none");
      this.hasRateInstallmentBeehiveTarget.classList.remove("d-none");
    } else {
      this.hasRateBeehiveTarget.classList.add("d-none");
      this.hasRateInstallmentBeehiveTarget.classList.add("d-none");
    }
  }

  showRateFieldZouti(e) {
    if (e.target.value == "true") {
      this.hasRateZoutiTarget.classList.remove("d-none");
      this.hasRateInstallmentZoutiTarget.classList.remove("d-none");
    } else {
      this.hasRateZoutiTarget.classList.add("d-none");
      this.hasRateInstallmentZoutiTarget.classList.add("d-none");
    }
  }

  showRateFieldHorizon(e) {
    if (e.target.value == "true") {
      this.hasRateHorizonTarget.classList.remove("d-none");
      this.hasRateInstallmentHorizonTarget.classList.remove("d-none");
    } else {
      this.hasRateHorizonTarget.classList.add("d-none");
      this.hasRateInstallmentHorizonTarget.classList.add("d-none");
    }
  }

  showRateFieldOrla(e) {
    if (e.target.value == "true") {
      this.hasRateOrlaTarget.classList.remove("d-none");
      this.hasRateInstallmentOrlaTarget.classList.remove("d-none");
    } else {
      this.hasRateOrlaTarget.classList.add("d-none");
      this.hasRateInstallmentOrlaTarget.classList.add("d-none");
    }
  }

  showRateFieldZoop(e) {
    if (e.target.value == "true") {
      this.hasRateZoopTarget.classList.remove("d-none");
      this.hasRateInstallmentTarget.classList.remove("d-none");
    } else {
      this.hasRateZoopTarget.classList.add("d-none");
      this.hasRateInstallmentTarget.classList.add("d-none");
    }
  }

  showRateFieldUltragate(e) {
    if (e.target.value == "true") {
      this.hasRateUltragateTarget.classList.remove("d-none");
      this.hasRateInstallmentUltragateTarget.classList.remove("d-none");
    } else {
      this.hasRateUltragateTarget.classList.add("d-none");
      this.hasRateInstallmentUltragateTarget.classList.add("d-none");
    }
  }

  showRateFieldNeverra(e) {
    if (e.target.value == "true") {
      this.hasRateNeverraTarget.classList.remove("d-none");
      this.hasRateInstallmentNeverraTarget.classList.remove("d-none");
    } else {
      this.hasRateNeverraTarget.classList.add("d-none");
      this.hasRateInstallmentNeverraTarget.classList.add("d-none");
    }
  }

  showRateFieldMevpay(e) {
    if (e.target.value == "true") {
      this.hasRateMevpayTarget.classList.remove("d-none");
      this.hasRateInstallmentMevpayTarget.classList.remove("d-none");
    } else {
      this.hasRateMevpayTarget.classList.add("d-none");
      this.hasRateInstallmentMevpayTarget.classList.add("d-none");
    }
  }

  showRateFieldHypepay(e) {
    if (e.target.value == "true") {
      this.hasRateHypepayTarget.classList.remove("d-none");
      this.hasRateInstallmentHypepayTarget.classList.remove("d-none");
    } else {
      this.hasRateHypepayTarget.classList.add("d-none");
      this.hasRateInstallmentHypepayTarget.classList.add("d-none");
    }
  }

  showRateFieldBrazapay(e) {
    if (e.target.value == "true") {
      this.hasRateBrazapayTarget.classList.remove("d-none");
      this.hasRateInstallmentBrazapayTarget.classList.remove("d-none");
    } else {
      this.hasRateHypepayTarget.classList.add("d-none");
      this.hasRateInstallmentBrazapayTarget.classList.add("d-none");
    }
  }

  showRateFieldZolluspay(e) {
    if (e.target.value == "true") {
      this.hasRateZolluspayTarget.classList.remove("d-none");
      this.hasRateInstallmentTarget.classList.remove("d-none");
    } else {
      this.hasRateZolluspayTarget.classList.add("d-none");
      this.hasRateInstallmentTarget.classList.add("d-none");
    }
  }

  showRateFieldMorgan(e) {
    if (e.target.value == "true") {
      this.hasRateMorganTarget.classList.remove("d-none");
      this.hasRateInstallmentMorganTarget.classList.remove("d-none");
    } else {
      this.hasRateMorganTarget.classList.add("d-none");
      this.hasRateInstallmentMorganTarget.classList.add("d-none");
    }
  }

  showRateFieldWhitemex(e) {
    if (e.target.value == "true") {
      this.hasRateWhitemexTarget.classList.remove("d-none");
      this.hasRateInstallmentWhitemexTarget.classList.remove("d-none");
    } else {
      this.hasRateWhitemexTarget.classList.add("d-none");
      this.hasRateInstallmentWhitemexTarget.classList.add("d-none");
    }
  }

  showRateFieldBlackBullPay(e) {
    if (e.target.value == "true") {
      this.hasRateBlackBullPayTarget.classList.remove("d-none");
      this.hasRateInstallmentBlackBullPayTarget.classList.remove("d-none");
    } else {
      this.hasRateBlackBullPayTarget.classList.add("d-none");
      this.hasRateInstallmentBlackBullPayTarget.classList.add("d-none");
    }
  }

  showRateFieldSoutpay(e) {
    if (e.target.value == "true") {
      this.hasRateSoutpayTarget.classList.remove("d-none");
      this.hasRateInstallmentSoutpayTarget.classList.remove("d-none");
    } else {
      this.hasRateSoutpayTarget.classList.add("d-none");
      this.hasRateInstallmentSoutpayTarget.classList.add("d-none");
    }
  }

  showRateFieldZyonPay(e) {
    if (e.target.value == "true") {
      this.hasRateZyonPayTarget.classList.remove("d-none");
      this.hasRateInstallmentZyonPayTarget.classList.remove("d-none");
    } else {
      this.hasRateZyonPayTarget.classList.add("d-none");
      this.hasRateInstallmentZyonPayTarget.classList.add("d-none");
    }
  }

  showRateFieldBestfy(e) {
    if (e.target.value == "true") {
      this.hasRateBestfyTarget.classList.remove("d-none");
      this.hasRateInstallmentBestfyTarget.classList.remove("d-none");
    } else {
      this.hasRateBestfyTarget.classList.add("d-none");
      this.hasRateInstallmentBestfyTarget.classList.add("d-none");
    }
  }

  showRateFieldSkalepay(e) {
    if (e.target.value == "true") {
      this.hasRateSkalepayTarget.classList.remove("d-none");
      this.hasRateInstallmentSkalepayTarget.classList.remove("d-none");
    } else {
      this.hasRateSkalepayTarget.classList.add("d-none");
      this.hasRateInstallmentSkalepayTarget.classList.add("d-none");
    }
  }

  showRateFieldDorapag(e) {
    if (e.target.value == "true") {
      this.hasRateDorapagTarget.classList.remove("d-none");
      this.hasRateInstallmentDorapagTarget.classList.remove("d-none");
    } else {
      this.hasRateDorapagTarget.classList.add("d-none");
      this.hasRateInstallmentDorapagTarget.classList.add("d-none");
    }
  }

  showRateFieldArkama(e) {
    if (e.target.value == "true") {
      this.hasRateArkamaTarget.classList.remove("d-none");
      this.hasRateInstallmentArkamaTarget.classList.remove("d-none");
    } else {
      this.hasRateArkamaTarget.classList.add("d-none");
      this.hasRateInstallmentArkamaTarget.classList.add("d-none");
    }
  }

  showRateFieldAurapay(e) {
    if (e.target.value == "true") {
      this.hasRateAurapayTarget.classList.remove("d-none");
      this.hasRateInstallmentAurapayTarget.classList.remove("d-none");
    } else {
      this.hasRateAurapayTarget.classList.add("d-none");
      this.hasRateInstallmentAurapayTarget.classList.add("d-none");
    }
  }

  showAdditionalFields() {
    let gateway = this.element.dataset.name;
    // TODO hide all forms and show only the selected one
  }

  selectForm(labelFor, formId) {
    const allForms = document.querySelectorAll("div.gateway-form-list > div");
    allForms.forEach((element, _) => {
      element.classList[element.id == formId ? "remove" : "add"]("d-none");
    });

    const allCheckboxes = document.querySelectorAll("label.card-select-kind");
    allCheckboxes.forEach((element, _) => {
      element.classList[
        element.getAttribute("for") == labelFor ? "add" : "remove"
      ]("active");
    });

    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 1;
      this.documentLabelTarget.innerHTML = "CNPJ";
      this.documentFieldTarget.placeholder = "CNPJ";
      this.documentFieldTarget.classList.remove("cpf");
      this.documentFieldTarget.classList.add("cnpj");
      refreshVMasker();
    }
  }

  changeGateway() {
    let gateway = this.gatewaySelectedTarget.value;
    if (gateway == '') {
      $(".gateway-form-list").addClass('d-none');
      $(".place-image-helper").addClass('d-none')
    } else {
      $(".gateway-form-list").removeClass('d-none');
      changeImageGateway(camelToUnderscore(gateway));
      this.selectForm("gateway" + camelToUnderscore(gateway), "form" + camelToUnderscore(gateway));
    }
  }

  showAdditionalFields0() { this.selectForm("gateway_select_1", "form_mercado_pago"); }
  showAdditionalFields1() { this.selectForm("gateway_select_2", "form_pag_seguro"); }
  showAdditionalFields2() { this.selectForm("gateway_select_3", "form_pagar_me"); }
  showAdditionalFields3() { this.selectForm("gateway_select_4", "form_abmex"); }
  showAdditionalFields4() { this.selectForm("gateway_select_5", "form_zoop"); }
  showAdditionalFields5() { this.selectForm("gateway_select_6", "form_akta"); }
  showAdditionalFields6() { this.selectForm("gateway_select_7", "form_horizon"); }
  showAdditionalFields7() { this.selectForm("gateway_select_8", "form_orla"); }
  showAdditionalFields8() { this.selectForm("gateway_select_9", "form_ultragate"); }
  showAdditionalFields9() { this.selectForm("gateway_select_10", "form_zolluspay"); }
  showAdditionalFields10() { this.selectForm("gateway_select_11", "form_morganpay"); }
  showAdditionalFields11() { this.selectForm("gateway_select_12", "form_arkama"); }
  showAdditionalFields13() { this.selectForm("gateway_select_14", "form_beehive"); }
  showAdditionalFields14() { this.selectForm("gateway_select_15", "form_noxpay"); }
  showAdditionalFields15() { this.selectForm("gateway_select_16", "form_cashtime"); }
  showAdditionalFields16() { this.selectForm("gateway_select_17", "form_dom_pagamento"); }
  showAdditionalFields17() { this.selectForm("gateway_select_18", "form_zouti"); }
  showAdditionalFields18() { this.selectForm("gateway_select_19", "form_morgan"); }
  showAdditionalFields19() { this.selectForm("gateway_select_20", "form_black_x_pay"); }
  showAdditionalFields20() { this.selectForm("gateway_select_21", "form_neverra"); }
  showAdditionalFields24() { this.selectForm("gateway_select_25", "form_hypepay"); }
  showAdditionalFields25() { this.selectForm("gateway_select_26", "form_brazapay"); }
  showAdditionalFields26() { this.selectForm("gateway_select_27", "form_mevpay"); }
  showAdditionalFieldsAbmexpay() { this.selectForm("gateway_abmexpay", "form_abmexpay"); }
  showAdditionalFieldsPayd() { this.selectForm("gateway_payd", "form_payd"); }
  showAdditionalFieldsSafepay() { this.selectForm("gateway_safepay", "form_safepay"); }
  showAdditionalFieldsZenith() { this.selectForm("gateway_zenith", "form_zenith"); }
  showAdditionalFieldsDragonPay() { this.selectForm("gateway_dragon_pay", "form_dragon_pay"); }
  showAdditionalFieldsInnovepay() { this.selectForm("gateway_innovepay", "form_innovepay"); }
  showAdditionalFieldsUnicopay() { this.selectForm("gateway_unicopay", "form_unicopay"); }
  showAdditionalFieldsMaxpay() { this.selectForm("gateway_maxpay", "form_maxpay"); }
  showAdditionalFieldsCashfy() { this.selectForm("gateway_cashfy", "form_cashfy"); }
  showAdditionalFieldsAxionpay() { this.selectForm("gateway_axionpay", "form_axionpay"); }
  showAdditionalFieldsSoutpay() { this.selectForm("gateway_soutpay", "form_soutpay"); }
  showAdditionalFieldsBestfy() { this.selectForm("gateway_bestfy", "form_bestfy"); }
  showAdditionalFieldsBlackBullPay() { this.selectForm("gateway_black_bull_pay", "form_black_bull_pay"); }
  showAdditionalFieldsCentrapay() { this.selectForm("gateway_centrapay", "form_centrapay"); }
  showAdditionalFieldsPaguemais() { this.selectForm("gateway_paguemais", "form_paguemais"); }
  showAdditionalFieldsRawart() { this.selectForm("gateway_rawart", "form_rawart"); }
  showAdditionalFieldsSummitpay() { this.selectForm("gateway_summitpay", "form_summitpay"); }
  showAdditionalFieldsCashfypaga() { this.selectForm("gateway_cashfypaga", "form_cashfypaga"); }
  showAdditionalFieldsWishpag() { this.selectForm("gateway_wishpag", "form_wishpag"); }
  showAdditionalFieldsProxypay() { this.selectForm("gateway_proxypay", "form_proxypay"); }
  showAdditionalFieldsTryplopay() { this.selectForm("gateway_tryplopay", "form_tryplopay"); }
  showAdditionalFieldsRoyalfy() { this.selectForm("gateway_royalfy", "form_royalfy"); }
  showAdditionalFieldsZyonPay() { this.selectForm("gateway_zyon_pay", "form_zyon_pay"); }
  showAdditionalFieldsEskypay() { this.selectForm("gateway_eskypay", "form_eskypay"); }
  showAdditionalFieldsDropipay() { this.selectForm("gateway_dropipay", "form_dropipay"); }
  showAdditionalFieldsSkalepay() { this.selectForm("gateway_skalepay", "form_skalepay"); }
  showAdditionalFieldsDorapag() { this.selectForm("gateway_dorapag", "form_dorapag"); }
}

function changeImageGateway(gateway) {
  $(".place-image-helper").removeClass("d-none")
  $(`.${gateway.substring(1)}`).hide()
  $(".image-place-replace").html($(`.${gateway.substring(1)}`).html())
}

function camelToUnderscore(str) {
  return (str.replace(/([A-Z])/g, '_$1')).toLowerCase();
}