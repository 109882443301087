// 'use strict';

import SimpleBar from "simplebar";
import feather from "feather-icons";

// Initialize SimpleBar for navbar content if available
if (document.querySelector(".navbar-content")) {
  new SimpleBar(document.querySelector(".navbar-content"));
}

// Function to handle menu click events (collpase menus and it's submenu also collapse)
function menu_click() {
  // Remove click event listeners from navigation menu items
  var elem = document.querySelectorAll(".pc-navbar li");
  for (var j = 0; j < elem.length; j++) {
    elem[j].removeEventListener("click", function () {});
  }

  // Hide submenu items (when menu link not active then submenu hide)
  var elem = document.querySelectorAll(
    ".pc-navbar li:not(.pc-trigger) .pc-submenu"
  );
  for (var j = 0; j < elem.length; j++) {
    elem[j].style.display = "none";
  }

  // Add click event listeners to main menu items (for first menu level collapse)
  var pc_link_click = document.querySelectorAll(
    ".pc-navbar > li:not(.pc-caption).pc-hasmenu"
  );
  for (var i = 0; i < pc_link_click.length; i++) {
    pc_link_click[i].addEventListener("click", function (event) {
      // Prevent parent elements from triggering their events
      event.stopPropagation();
      var targetElement = event.target;
      if (targetElement.tagName == "SPAN") {
        targetElement = targetElement.parentNode;
      }
      // Toggle submenu visibility (active remove who has menu link not clicked and it's submenu also hide)
      if (targetElement.parentNode.classList.contains("pc-trigger")) {
        targetElement.parentNode.classList.remove("pc-trigger");
        slideUp(targetElement.parentNode.children[1], 200);
        window.setTimeout(() => {
          targetElement.parentNode.children[1].removeAttribute("style");
          targetElement.parentNode.children[1].style.display = "none";
        }, 200);
      } else {
        // Close other open submenus
        var tc = document.querySelectorAll("li.pc-trigger");
        for (var t = 0; t < tc.length; t++) {
          var c = tc[t];
          c.classList.remove("pc-trigger");
          slideUp(c.children[1], 200);
          window.setTimeout(() => {
            c.children[1].removeAttribute("style");
            c.children[1].style.display = "none";
          }, 200);
        }

        // Open clicked submenu (for active menu link)
        targetElement.parentNode.classList.add("pc-trigger");
        var submenu_list = targetElement.children[1];
        if (submenu_list) {
          slideDown(targetElement.parentNode.children[1], 200);
        }
      }
    });
  }

  // Initialize SimpleBar for navbar content if available
  if (document.querySelector(".navbar-content")) {
    new SimpleBar(document.querySelector(".navbar-content"));
  }

  // Add click event listeners to submenu items
  var pc_sub_link_click = document.querySelectorAll(
    ".pc-navbar > li:not(.pc-caption) li.pc-hasmenu"
  );
  for (var i = 0; i < pc_sub_link_click.length; i++) {
    pc_sub_link_click[i].addEventListener("click", function (event) {
      var targetElement = event.target;
      if (targetElement.tagName == "SPAN") {
        targetElement = targetElement.parentNode;
      }
      event.stopPropagation();
      // Toggle submenu visibility
      if (targetElement.parentNode.classList.contains("pc-trigger")) {
        targetElement.parentNode.classList.remove("pc-trigger");
        slideUp(targetElement.parentNode.children[1], 200);
      } else {
        // Close other open submenus
        var tc = targetElement.parentNode.parentNode.children;
        for (var t = 0; t < tc.length; t++) {
          var c = tc[t];
          c.classList.remove("pc-trigger");
          if (c.tagName == "LI") {
            c = c.children[0];
          }
          if (c.parentNode.classList.contains("pc-hasmenu")) {
            slideUp(c.parentNode.children[1], 200);
          }
        }

        // Open clicked submenu
        targetElement.parentNode.classList.add("pc-trigger");
        var submenu_list = targetElement.parentNode.children[1];
        if (submenu_list) {
          submenu_list.removeAttribute("style");
          slideDown(submenu_list, 200);
        }
      }
    });
  }
}

// Initialize menu click function on DOMContentLoaded (function call on page load)
document.addEventListener("DOMContentLoaded", menu_click);

// Scroll to the active menu
document.addEventListener("DOMContentLoaded", () => {
  const simpleBarContentWrapper = document.querySelector(
    ".simplebar-content-wrapper"
  );
  const currentActiveMenu = document.querySelector(
    ".pc-navbar > li:not(.pc-caption).active"
  );

  console.log({ currentActiveMenu });

  if (currentActiveMenu) {
    console.log({
      currentActiveMenuY: currentActiveMenu.getBoundingClientRect().y,
    });

    simpleBarContentWrapper.scrollTo({
      top: currentActiveMenu.getBoundingClientRect().y,
      behavior: "smooth",
    });
  }
});

// Functions for sliding up, sliding down, and toggling visibility of elements
let slideUp = (target, duration = 0) => {
  // Slide up animation implementation
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.boxSizing = "border-box";
  target.style.height = target.offsetHeight + "px";
  target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
};

let slideDown = (target, duration = 0) => {
  // Slide down animation implementation
  target.style.removeProperty("display");
  let display = window.getComputedStyle(target).display;

  if (display === "none") display = "block";

  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = "border-box";
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.height = height + "px";
  target.style.removeProperty("padding-top");
  target.style.removeProperty("padding-bottom");
  target.style.removeProperty("margin-top");
  target.style.removeProperty("margin-bottom");
  window.setTimeout(() => {
    target.style.removeProperty("height");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
  }, duration);
};

// for sidebar close
var sidebar_hide = document.querySelector("#sidebar-hide");
if (sidebar_hide) {
  sidebar_hide.addEventListener("click", function () {
    if (
      document
        .querySelector(".pc-sidebar")
        .classList.contains("pc-sidebar-hide")
    ) {
      document.querySelector(".pc-sidebar").classList.remove("pc-sidebar-hide");
      document
        .querySelector("main.main-panel")
        .classList.add("with-new-sidebar");
    } else {
      document.querySelector(".pc-sidebar").classList.add("pc-sidebar-hide");
      document
        .querySelector("main.main-panel")
        .classList.remove("with-new-sidebar");
    }
  });
}

// Function to mark active menu items based on current page URL
var elem = document.querySelectorAll(".pc-sidebar .pc-navbar a");
for (var l = 0; l < elem.length; l++) {
  // Check if current URL matches menu item URL
  var pageUrl = window.location.href.split(/[?#]/)[0];
  if (elem[l].href == pageUrl && elem[l].getAttribute("href") != "") {
    // Add active class to matching menu item and its parent elements
    elem[l].parentNode.classList.add("active");
    elem[l].parentNode.parentNode.parentNode.classList.add("pc-trigger");
    elem[l].parentNode.parentNode.parentNode.classList.add("active");
    elem[l].parentNode.parentNode.style.display = "block";
    elem[
      l
    ].parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
      "pc-trigger"
    );
    elem[l].parentNode.parentNode.parentNode.parentNode.style.display = "block";
  }
}

// Menu collapse click start (when click it's open and close sidebar for mobile screen)
var mobile_collapse_over = document.querySelector("#mobile-collapse");
if (mobile_collapse_over) {
  mobile_collapse_over.addEventListener("click", function () {
    var mobile_sidebar = document.querySelector(".pc-sidebar");
    if (mobile_sidebar) {
      if (
        document
          .querySelector(".pc-sidebar")
          .classList.contains("mob-sidebar-active")
      ) {
        rm_menu();
      } else {
        document
          .querySelector(".pc-sidebar")
          .classList.add("mob-sidebar-active");
        // add overlay when sidebar open
        document
          .querySelector(".pc-sidebar")
          .insertAdjacentHTML(
            "beforeend",
            '<div class="pc-menu-overlay"></div>'
          );
        document
          .querySelector(".pc-menu-overlay")
          .addEventListener("click", function () {
            rm_menu();
          });
      }
    }
  });
}
// Menu collapse click end

// Function to hide mobile menu (sidebar hide on click overlay)
function rm_menu() {
  // Remove active class from mobile menu elements
  var menu_list = document.querySelector(".pc-sidebar");
  if (menu_list) {
    document
      .querySelector(".pc-sidebar")
      .classList.remove("mob-sidebar-active");
  }
  if (document.querySelector(".topbar")) {
    document.querySelector(".topbar").classList.remove("mob-sidebar-active");
  }

  // Remove menu overlay elements
  document.querySelector(".pc-sidebar .pc-menu-overlay").remove();
  if (document.querySelector(".topbar .pc-menu-overlay")) {
    document.querySelector(".topbar .pc-menu-overlay").remove();
  }
}
